import { register } from '../../util/rmi-server-util'
import Executive from '../../util/rmi-util'

export { User, Role } from '../entities'

export default class FastifyTestExecutive extends Executive {

	initServer() {
	}

	async test(param: string): Promise<string> {
		return 'echo2:' + param
	}
}

register(FastifyTestExecutive)
<template>
	<DialogV2 ref="dialogRef"
		:confirmLabel="confirmLabel"
		:cancelLabel="cancelLabel"
		:onConfirm="onConfirm"
		:onCancel="onCancel"
		:closeOnConfirm="false"
		:closeOnCancel="false"
		:disableConfirmButton="disableConfirmButton"
		:currentStep="currentStep"
		:steps="steps"
		width="90vw"
		height="95vh"
		:loading="loading"
	>
		<template #title>
			<v-toolbar-title>
				<span>{{ currentStepInfo?.isProductsSelection || currentStepInfo?.isProductsConfig ? $t("text.newAssignment") : $t("text.newPackage") }}</span>
				<p class="sub-title">{{ subTitle }}</p>
			</v-toolbar-title>
		</template>

		<!-- Search / Filter Header -->
		<template #header-utility v-if="currentStepInfo?.isProductsSelection">
			<div style="border-bottom: 1px solid rgb(221, 221, 221);" class="px-8 py-3">
				<div class="d-flex align-center ga-8">
					<div class="d-flex ga-4 flex-grow-1">
						<v-text-field
							variant="outlined"
							density="compact"
							clearable
							hide-details
							prepend-inner-icon="mdi-magnify"
							:placeholder="$t('text.searchPeakSpPlaceholder')"
							v-model="filters.searchString"
							@keyup.enter="search"
							@update:modelValue="filters.searchString = $event"
							@click:clear="clear"
							data-cy="search-field"
						/>
						<v-btn class="blueButton" theme="dark" elevation="0" @click="search" data-cy="searchButton">
							{{ $t('text.search') }}
						</v-btn>
					</div>
					<ProductFilters :onApplyFilter="applyFilters" :productType="productType" />
				</div>
			</div>
		</template>

		<!-- Content -->
		<template #content>
			<div>
				<!-- Package Specific -->
				<div v-if="currentStepInfo?.isPricingTypeSelection">
					<Field
						typeName="Package"
						fieldName="pricingType"
						:title="$t('text.pricingLabel')"
						:infoText="$t('text.pricingDescription')"
						v-model="modelValue.fields.pricingType"
					/>
				</div>
				<!-- Products -->
				<div v-if="currentStepInfo?.isProductsSelection">
					<div class="content">
						<p class="title">{{ $t('text.chooseProducts') }}</p>
						<p>{{ $t('text.chooseProductsHelp') }}</p>
					</div>
						<!-- TODO: how to pass in a current selection list -->
					<ProductsTable v-model:items="products"
						:total="99999"
						:limit="filters.limit" @update:limit="updateLimit"
						:offset="filters.offset" @update:offset="updateOffset"
						:loading="loading"
						v-model:selected="selected"
					/>

				<!-- Add Configurable Bergbahn Product Dialog -->
				<ConfigurableBergbahnProductDialog ref="configurableBergbahnProductDialog" :type="type" :products="products" @finished="autoSelectProduct" />

				</div>
				<!-- Product Config -->
				<div v-if="currentStepInfo?.isProductsConfig">
					<v-progress-linear v-show="loading" :model-value="addedValuesProgress" color="green" height="8"></v-progress-linear>
					
					<FieldSet id="addedOfferDefinitions" class="step-2-content-item" />
					<DisclosureItems :modelValue="addedValues" ref="disclosureItems" :class="{overlayed: loading}">
						<template #itemHead="{ item, expanded }">
							<div class="expansionPanelHeader">
								<div><v-icon :icon="expanded ? 'mdi-chevron-up' : 'mdi-chevron-down'" />{{ item.fields.name.de }}</div>
								<!-- @customizeOptions="$refs['optionalSettingsDialog-' + item.sys.id]?.[0]?.open?.()" -->
								<AddedValueType :isRailawayPackage="isRailawayPackage" :skus="item.fields?.sku?.de" :id="item?.sys?.id"
									v-model="selectedAvType[item?.sys?.id]" @update:modelValue="defaultAddedValueFieldsPerType" />
							</div>
						</template>

						<template #itemBody="{ item }" >
							<AddedValueForm :modelValue="item" :pricingType="pricingType" :selectedAvType="selectedAvType[item?.sys?.id]" />
						</template>
					</DisclosureItems>
				</div>
			</div>

			<!-- Sales Channels Mismatch Dialog -->
			<DialogV2 ref="salesChannelsMismatchDialogRef"
				:title="$t('text.salesChannels')"
				:cancelLabel="$t('text.editProductSelection')"
				:onConfirm="onConfirmSalesChannelsMismatch"
				:onCancel="onCancelSalesChannelsMismatch"
				:closeOnOuterClick="false"
				width="480px"
				height="355px"
			>
				<template #content>
					<div class="mismatch-wrapper">
						<v-icon size="90px" color="blue" class="mr-10">mdi-information</v-icon>
						<div class="">
							<p class="mismatch-title">{{ $t('text.salesChannelsMismatch') }}</p>
							<p class="mismatch-info">{{ $t('text.salesChannelsMismatchInfo') }}</p>
						</div>
					</div>
				</template>
			</DialogV2>
		</template>

		<template #buttons v-if="currentStepInfo?.isProductsSelection">
			<AddButton v-if="productType === 'MRW_CONFIG'" @click="openConfigurableBergbahnProductDialog" buttonText="configurableProduct" class="addMRWButton"/>
		</template>

	</DialogV2>
</template>

<script>
import isEmpty from 'lodash/isEmpty'
import omitBy from 'lodash/omitBy'
import DialogV2 from "@/components/common/DialogV2.vue"
import Common from "@/mixins/Common.vue"
import FieldSet from '../FieldSet.vue'
import ProductFilters from '../ProductFilters.vue'
import ProductsTable from '../tables/ProductsTable.vue'
import LanguagesNavigation from '@/mixins/LanguagesNavigation.vue'
import AddedValueType from '../AddedValueType.vue'
import Table from '../Table.vue'
import DisclosureItems from '../added-values/DisclosureItems.vue'
import AddedValueForm from './AddedValueForm.vue'
import Field from '@/components/fields/Field.vue'
import AddButton from '../AddButton.vue'
import DataDialog from '../../../../components/common/DataDialog.vue'
import ConfigurableBergbahnProductDialog from './ConfigurableBergbahnProductDialog.vue'
import { AV_TYPES } from '@/constants/addedValueOffers.ts'
import AddedValues from '@/mixins/AddedValues.vue'
import eventBus from '@/utils/eventBus.js'

export default {
	name: 'NewPackageDialog',
	components: { DialogV2, ProductsTable, ProductFilters, FieldSet, AddedValueType, Table, DisclosureItems, AddedValueForm, Field, AddButton, DataDialog, ConfigurableBergbahnProductDialog },
	mixins: [ Common, LanguagesNavigation, AddedValues ],
	props: {
		// whole package
		modelValue: Object,
		linkedEntries: Object,
		hasNameStep: Boolean,
		hasPricingTypeSelectionStep: Boolean,
		productType: String, // 'OTHER' | 'SKIPASS' | 'MRW_CONFIG | 'MRW_VIRTUAL'
	},
	data: () => ({
		products: [],
		currentStep: 1,
		loading: true,
		total: 0,
		selected: [],
		filters: { searchString: '', limit: 10, offset: 0 },
		model: null,
		addedValues: [],
		// addedValueId => { key => boolean }
		optionalSettings: {},
		addedValuesProgress: 0,
		selectedAvType: {},
		confirmedSalesChannelMismatch: false,
	}),
	computed: {
		hasSalesChannelMismatch() {
			const packageSalesChannels = this.modelValue?.fields?.websites?.de?.map(channel => channel.name)

			return this.selected.some(selectedAv => {
				let av = selectedAv

				if (Number.isInteger(av)) {
					av = this.products.find(p => p?.product_id === selectedAv)
				}

				if (!av) return false

				return !av.product_websites?.some(e => packageSalesChannels.includes(e?.product_website_name))
			})
		},
		pricingType() {
			return this.modelValue?.fields?.pricingType?.de ?? false
		},

		disableConfirmButton() {
			if (this.currentStepInfo?.isPricingTypeSelection) return !this.pricingType
			if (this.currentStepInfo?.isProductsSelection) return !this.selected.length

			return false
		},
		type() {
			return this.model?.fields?.packageType?.de ?? this.$store.state?.selectedServiceType?.fields?.ticketType?.de ?? 'package-ov'
		},
		existingProductsSKU() {
			const result = []

			this.model?.fields?.addedValues?.de?.forEach(({ sys: { id } }) => {
				const item = this.linkedEntries[id]
				if (!item) return
				result.push(...item.fields?.sku?.de ?? [])
			})

			return result
		},
		steps() {
			const steps = [
				{ title: this.$t('text.products'), icon: 'mdi-shape', isProductsSelection: true },
				{ title: this.$t('text.productConfig'), icon: 'mdi-cog', isProductsConfig: true },
			]

			if (this.hasPricingTypeSelectionStep) steps.unshift({ title: this.$t('text.packageSpecific'), icon: 'mdi-package-variant-closed', isPricingTypeSelection: true })

			if (this.hasNameStep) steps.push({ title: this.$t("text.name"), icon: "mdi-translate" })

			return steps.map((step, index) => ({ ...step, stepNr: index + 1 }))
		},
		currentStepInfo() {
			return this.steps[this.currentStep - 1]
		},
		subTitle() {
			if (this.currentStepInfo?.isProductsSelection) return this.$t('text.includedProducts')
			if (this.currentStepInfo?.isProductsConfig) return this.$t('text.addedValueOfferConfiguration')

			return null
		},
		confirmLabel() {
			if (this.currentStep === this.steps.length) return this.$t('text.save')

			return this.$t('text.next')
		},
		cancelLabel() {
			if (this.currentStep === 1) return this.$t('text.cancel')
			
			return this.$t('text.back')
		},
		step2Model() {
			// TODO: revise this. Should we make a get call to the api to get added values based on the id-s in the selected array prop? Or should the selected array prop already contain the details of the added values?
			return this.model?.fields?.addedValues?.de ?? []
		},
		isRailawayPackage() {
			return this.type === 'package-ov-skipass' || this.type === 'package-ov'
		},
		isGeneralPackage() {
			return this.type === 'package-general'
		},
	},
	watch: {
		model(n) { this.$emit('update:modelValue', n) },
		modelValue: {
			deep: true,
			handler(n) {
				this.model = n
				//this.validate()
			},
		},
		addedValues(n) {
			// TODO: check if we need to update smth here
			for (const addedValue of n ?? []) {
				this.optionalSettings[addedValue.sys.id] = {}
			}
		},
		currentStep(value) {
			const newCurrentStepInfo = this.steps[value - 1]
			if (newCurrentStepInfo?.isProductsSelection && !this.products?.length) this.getProducts({ total: true })
		},
	},
	methods: {
		async open() {
			// TODO: this is WIP
			const avLinks = this.modelValue?.fields?.addedValues?.de ?? []
			const avs = avLinks.map(({ sys: { id } }) => this.linkedEntries[id])
			const skus = this.type === 'package-ov-skipass'
				? avs.flatMap(av => av?.fields?.skipassProducts?.de ?? av?.fields?.sku?.de)
				: avs.map(av => av?.fields?.sku?.de)
			console.log('NPD.open', 'avs', avs, 'skus', skus)
			// TODO: we dont have the products loaded here yet - and even if we did it after "getProducts"
			//       we would not necessarily see the ones that are already selected in the table (pagination)
			//       -> we have to use the skus as selection model!
			//       -> needs refactor!
			//         - <Table> will need to expose the "item-value" prop of the v-data-table
			//         - we will have to implement "selected" as an array of skus
			//         - the "create added values" will have to  be skus based
			//         - in the end we will have to add / remove skus from the skipassProducts array
			//         - non-skipasses should probably NOT allow deselection
			// TODO: maybe we should find a different approach instead, as this whole refactor looks very complex and risky.
			//       we could allow removing skipass skus from the skipass AV with an [x] button
			//       and allow adding new ones with a simpler control (combo box)
			//console.log('NPD.open - products', products)
			// TODO: set this.selected = ... so we can show the selected ones in the table
			this.selected = []

			this.$refs.dialogRef.open()
			await this.getProducts({ total: true })

			this.resetMeta()
		},
		close() {
			this.$refs.dialogRef.close()
		},
		resetMeta() {
			this.currentStep = 1
			this.addedValuesProgress = 0
		},
		async onConfirm() {
			if (this.hasSalesChannelMismatch && !this.confirmedSalesChannelMismatch) {
				this.$refs.salesChannelsMismatchDialogRef.open()
				return
			}

			if (this.currentStepInfo?.isProductsSelection) {
				this.addedValueFactory()
			}

			if (this.currentStepInfo?.isProductsConfig) {
				await this.save()
				this.$emit('finished', this.model)
			}

			this.currentStep++
		},
		onConfirmSalesChannelsMismatch() {
			this.confirmedSalesChannelMismatch = true
			this.onConfirm()
		},
		onCancelSalesChannelsMismatch() {
			this.confirmedSalesChannelMismatch = false
		},
		initSelectedAvType(id, avType) {
			if (avType) {
				this.selectedAvType[id] = avType
				return avType
			}

			let defaultAvType = AV_TYPES.MISCONFIGURED

			if (this.isRailawayPackage) {
				defaultAvType = AV_TYPES.OV
			} else if (this.isGeneralPackage) {
				defaultAvType = this.pricingType === 'sum' ? AV_TYPES.FIXED : AV_TYPES.INCLUSIVE
			}

			this.selectedAvType[id] = defaultAvType

			return defaultAvType
		},
		getAvName(product, mode = 'other') {
			let name

			// use the product name if its there already (only on skipasses)
			if (mode == 'skipass')
				name = this.model.fields.name?.de

			// use the name from the product
			if (!name && product?.product_name?.length > 0) {
				// default store
				let storeVal = product.product_name.find(storeVal => storeVal.store_id == 0)
					// fallback to use just the first best store
					?? product.product_name[0]
				console.log('storeVal', storeVal)
				name = storeVal?.value?.substring?.(0, 50)
			}

			if (!name) name = 'PT' + product.product_id
			if (mode == 'skipass') name = 'Skipass Dummy ' + name + ' ' + product.product_id
			return name
		},
		addedValueFactory() {
			if (this.productType == 'OTHER' || this.productType == 'MRW_CONFIG') this.createAddedValuesForSelectedProducts()
				else if (this.productType === 'SKIPASS' || this.type === 'package-ov-skipass') this.createSkipassAddedValue()
				// TODO: different default values for the general package case + potentially different mandatory vs optional fields
				else this.createAddedValuesForSelectedProducts()
		},
		createAddedValuesForSelectedProducts() {
			if (!['package-ov', 'package-ov-skipass', 'package-general'].includes(this.type)) throw "Unsupported package type"

			this.addedValues = this.selected.map((product, index) => {
				let selectedProduct
				if (typeof product === 'number') {
					selectedProduct = this.products.find(p => p.id === product)
				} else {
					selectedProduct = product
				}

				return this.createAddedValuesForPackage(selectedProduct, index)
			})
		},
		createSkipassAddedValue() {
			// we want to only create one added value from all selected products that contains their skus in a property called skipassSkus
			let skipassSkus
			let avName
			// the toggleSelectAll in the table returns an array of id-s instead of objects like the normal selection. I tried to make the change there to return an array of objects as well but it was then acting weird in the UI and the select boxes were not selected even though the data was looking correct. I settled for this solution for now.
			if (typeof this.selected[0] === 'number') {
				avName = this.getAvName(this.products.find(p => p.id === this.selected[0]), 'skipass')
			 	skipassSkus = this.selected.map(id => {
					const product = this.products.find(p => p.id === id)
					return product ? product.product_sku : null
				})
			}
			else {
				avName = this.getAvName(this.selected[0], 'skipass')
				skipassSkus = this.selected.map(product => product.product_sku)
			}

			const id = 'AV_' + Math.floor(Math.random() * 1000)
			const type = this.initSelectedAvType(id)

			this.addedValues = [{
				sys: { id },
				fields: {
					type: { de: type },
					name: { de: avName },
					reductionPercent: { de: this.getDefaultReductionPercent(this.selectedAvType[id]) },
					seasonalDiscounts: { de: [] },
					hasContingent: { de: 'n' },
					maximumRedemptionCount: { de: null },
					validityType: { de: this.isRailawayPackage ? 'fixed' : null },
					validDaysBefore: { de: 0 },
					validDaysAfter: { de: 0 },
					directPurchase: { de: this.getDefaultDirectPurchase(this.selectedAvType[id]) },
					showPrice: { de: true },
					marketingText: { de: null },
					price: { de: 0 },
					// the sku of the created virtual skipass dummy product will be added in the backend
					sku: { de: [] },
					skipassProducts: { de: skipassSkus },
					websites: { de: this.mergeWebsites() },
					roundingPrecision: { de: this.getDefaultRoundingPrecision() },
					roundingMode: { de: this.getDefaultRoundingMode() },
					purchase: { de: 'booking' },
					required: { de: true },
				}
			}]
		},
		mergeWebsites() {
			const uniqueWebsites = new Set();

			const getWebsiteObj = (website) => ({
				website_id: website?.product_website_id,
				website_name: website?.product_website_name,
			})

			const addUniqueWebsite = (websiteObj) => {
				const websiteExists = Array.from(uniqueWebsites).some((w) => w.website_id === websiteObj.website_id)
				if (!websiteExists) {
					uniqueWebsites.add(websiteObj)
				}
			}

			this.selected.forEach((item) => {
				const products = typeof item === 'number' ? [this.products.find((p) => p.id === item)] : [item]
				products.forEach((product) => {
					product.product_websites?.forEach((website) => {
						const websiteObj = getWebsiteObj(website)
						addUniqueWebsite(websiteObj)
					});
				});
			});

			return Array.from(uniqueWebsites)
		},
		async save() {
			const totalAddedValues = this.addedValues.length
			let completedAddedValues = 0

			for (const addedValue of this.addedValues) {
				console.log('inside for loop addedValue', addedValue)
				try {
					await this.createAddedValue(addedValue)
					completedAddedValues++
					this.addedValuesProgress = Math.floor((completedAddedValues / totalAddedValues) * 100)
				}
				catch (error) {
					console.error('Failed to create added value:', error)
					continue
				}
			}

			if (completedAddedValues === totalAddedValues) {
				console.log('All added values created successfully')
				// TODO: translated success message
				eventBus.$emit('addToastMessage', this.$t('text.changesSuccessfullyProcessed'), 'success')
			}
			else {
				console.log('Some added values failed to create')
				eventBus.$emit('addToastMessage', this.$t('text.someAddedValuesFailedToCreate'), 'error')
			}
			// TODO: do not emit this here but update the model in the parent component (on finished)
			// this.$emit('update:modelValue', this.model)
			console.log('Model', this.model)
			console.log('Linked Entries', this.linkedEntries)
		},
		onCancel() {
			if (this.currentStep == 1) {
				this.clear()
				this.close()
				// had to remove this, because adding on existing packages also navigated to the list
				//this.$router.push('/packages')
			}
			const newStep = this.currentStep - 1
			this.currentStep = newStep < 1 ? 1 : newStep
			this.selected = []

			if (this.currentStep === 1) this.confirmedSalesChannelMismatch = false
		},
		async createAddedValue(addedValue) {
			try {
				this.loading = true
				const payload = {
					addedValue: addedValue,
					clientId: this.$store.state.selectedClient.sys.id,
					serviceProviderName: this.$store.state.selectedServiceProvider?.fields?.title?.de,
					package: this.model,
				}
				const packageId = this.$route.params.id
				const r = await this.$httpPost(`/packageTravel/package/${packageId}/addedValue?packageType=${this.type}`, payload)

				// TODO: the parent component should be responsible for updating the model, not this component. Do that on finished event. Try to emit the permission id
				const addedValueId = 'AV_' + r.permissionDefinitionId
				this.model.fields.addedValues.de.push({ sys: { id: addedValueId } })
				addedValue.sys.id = addedValueId
				// TODO: we cannot just assign the client-side added value object to the linked entries because we do not know the assigned websites before-hand. We need te instead fetch the corresponding 
				this.linkedEntries[addedValueId] = addedValue
				// this structure below is needed to identify AVs from other linked entries. Currently used to fetch the underlying product data that is used to show the product count on sales channels
				this.linkedEntries[addedValueId].sys.content_type = { sys: { id: 'AddedValue' } }
				console.log('Added Value Created. Linked Entries object: ', this.linkedEntries[addedValueId])
			}
			finally {
				this.loading = false
			}
		},
		async getProducts({ total } = { total: false }) {
			try {
				if (!this.currentStepInfo?.isProductsSelection) return

				this.loading = true
				const productFilters = { ...omitBy(this.filters, value => isEmpty(value?.toString())) }
				const clientId = this.$store.state.selectedClient.sys.id
				productFilters.productType = this.productType

				const r = await this.$httpPost(`/packageTravel/products?clientId=${clientId}`, { ...productFilters, type: this.type })
				this.products = r.products.map(item => ({
					...item,
					activities: omitBy(item.activities, isEmpty), id: item.product_id,
					disableSelectRow: this.existingProductsSKU.includes(item.product_sku),
				}))
			}
			catch (error) {
				this.errorTitle = this.$t('text.ERROR')
				this.errorDetail = error.response ? error.response.error : error
			}
			finally {
				this.loading = false
			}
		},
		search() {
			this.filters.offset = 0
			this.getProducts({ total: true })
		},
		clear() {
			this.filters.offset = 0
			this.filters.categories = []
			this.filters.priceRangeFrom = null
			this.filters.priceRangeTo = null
			this.filters.salesChannels = []
			this.filters.status = []
			this.filters.searchString = ''
			this.getProducts({ total: true })
		},
		updateLimit(limit) {
			this.filters.offset = 0
			this.filters.limit = limit
			this.getProducts()
		},
		updateOffset(offset) {
			this.filters.offset = offset
			this.getProducts()
		},
		async applyFilters(filters) {
			this.filters = { ...this.filters, ...filters }
			this.getProducts({ total: true })
		},
		createAddedValuesForPackage(selectedProduct, index, avType, existingId) {
			const id = existingId || 'AV_' + selectedProduct.id + index + Math.floor(Math.random() * 1000)

			const type = this.initSelectedAvType(id, avType)

			return {
					productId: selectedProduct.id,
					sys: { id },
					fields: {
						...this.mapAddedValueFields(type),
						name: { de: this.getAvName(selectedProduct) },
						sku: { de: [selectedProduct.product_sku] },
						websites: { de: selectedProduct.product_websites?.map(website => ({ website_id: website?.product_website_id, website_name: website?.product_website_name })) },
						sortOrder: { de: index },
					}
				}
		},
		openConfigurableBergbahnProductDialog() {
			this.$refs.configurableBergbahnProductDialog.open()
		},
		async autoSelectProduct(productId) {
			this.selected = [productId]
			// re-fetch the products so we have the latest created one in the list
			await this.getProducts()
			// proceed to the next step
			this.onConfirm()
		},
		defaultAddedValueFieldsPerType(id, avType) {
			if (!['package-ov', 'package-ov-skipass', 'package-general'].includes(this.type)) throw "Unsupported package type"

			const avIndex = this.addedValues.findIndex(av => av.sys.id === id)
			const index = this.selected.findIndex(product => product.id === this.addedValues[avIndex]?.productId)

			if (avIndex === -1 || index === -1) return

			const selectedProduct = this.selected[index]

			this.addedValues[avIndex] = this.createAddedValuesForPackage(selectedProduct, index, avType, id)
		},
	},
	async mounted() {
		this.model = JSON.parse(JSON.stringify(this.modelValue))
	},
};
</script>

<style scoped lang="scss">
.sub-title {
	line-height: normal;
	color: #b1b1b0;
}

.content {
	p {
		font-size: 12px;
		line-height: 16px;
		color: black;
	}

	.title {
		font-size: 17px;
		line-height: 22px;
		font-weight: bold;
	}
}

.tableCard {
	margin-top: 24px;
}

.offers-list {
	padding: 0;
	background-color: #F4F4F4;

	.active {
		background-color: #58A9EE;
	}
}

.disabled {
	opacity: 0.5;
	pointer-events: none;
}

.expansionPanelHeader {
	display: flex;
	gap: 20px;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.step-2-content-item {
	margin-bottom: 20px; 
	margin-left: 240px;
}

.overlayed {
	pointer-events: none;
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(255, 255, 255, 0.5);
		z-index: 99;
	}
}

.addMRWButton {
	position: fixed;
	left: 20px;
}

.mismatch-wrapper {
	display: flex;
	align-items: center;

	.mismatch-title {
		font-size: 20px;
	}

	.mismatch-info {
		padding-top: 12px;
		font-size: 16px;
		color: grey;
		line-height: 1.4;
	}
}
</style>

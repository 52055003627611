<template>
	<Step id="packageOffers" icon="mdi-plus">
		<Loading :active="loading" :is-full-page="true" color="#4caf50" data-cy="loading"></Loading>
		<Section id="name" open>
			<Field typeName="Package" fieldName="name" v-model="modelValue.fields.name" dataCy='packageName' />
			<Field title="SKU">
				<!-- TODO: disable when the package is created..
					TODO: should we even have this enabled EVER here? or should we rather put it with the name editor in the modal?
				-->
				<SkuEditor id="pdSku"
					v-model="modelValue.fields.sku.de"
					:parts="[
						{ id: 'prefix', defaultValue: 'PACKAGETRAVEL' },
						{ id: 'group', defaultValue: '', editable: true },
						{ id: 'location', defaultValue: '', editable: true },
					]"
				/>
			</Field>
		</Section>
		<Section id="channels" open>
			<Field :title="$t('text.salesChannels')" required>
				<PackageSalesChannels v-model="model" :products="products" :salesChannels="salesChannels" />
			</Field>
		</Section>
		<Section id="addedOffers" open>
			<FieldSet id="addedOffers" infoText="addedOffersHelp">
				<IfFeatureFlag flag="FEATURE_GPD_AddButtonList">
					<AddButtonList buttonText="addedOffers" :options="['OTHER', 'SKIPASS', 'MRW_CONFIG']" :option-disabled="{ SKIPASS: isRailawayClientWithAtLeastOneAV }" :disabled="isRailawaySkipassPackage && addedValueItems?.length" @option-click="setProductTypeAndOpenAvDialog" />
				</IfFeatureFlag>
				<AddButton v-if="!(isRailawaySkipassPackage && addedValueItems?.length)" @click="updateAddedValueProducts" buttonText="addedOffers" />
				<div :class="{ 'mismatch': salesChannelMismatch }">
					<SectionTable
						:items="addedValueItems"
						:hideFooter="true"
						:columns="[
							// TODO: width does not seem to work right - i think the table treats this as 0 width
							{ id: '', type: 'warning', width: '50px' },
							{ id: 'product', XXwidth: '230px' },
							{ id: 'salesChannels', type: 'reduced', limit: 2 },
							'type',
							{ id: 'reduction', title: 'reductionPercent' },
							'redemption',
							'validity',
							'purchase',
							{ id: '', type: 'options'},
						]"
						:options="[
							{ label: 'text.edit', icon: 'mdi-pencil', action: 'edit' },
							{ label: 'text.editChannel', icon: 'mdi-pencil', action: 'edit-channel' },
							{ label: 'text.delete', icon: 'mdi-delete', action: 'delete' },
							{ label: 'text.moveUp', icon: 'mdi-arrow-up', action: 'moveUp' },
							{ label: 'text.moveDown', icon: 'mdi-arrow-down', action: 'moveDown' },
						]"
						no-data-text="noAdditionalProductsAdded"
						@click-item="editAddedValue"
						@option-edit="editAddedValue"
						@option-edit-channel="editAddedValueChannels"
						@option-delete="deleteAddedValue($event)"
						@option-moveUp="moveUp"
						@option-moveDown="moveDown"
					>
						<!-- <template #warning="{ item }">
							<div v-if="item.addl && item.addl.valid == false" style="padding-left: 5px">
								<v-icon color="orange">mdi-alert</v-icon><b>{{$t('text.invalidCouponConfig')}}</b>
								<v-sheet border="error lg" rounded></v-sheet>
							</div>
						</template> -->
						<template #warning-row="{ item }">
							<div v-if="addedValueValidations && addedValueValidations[item.sku] && !addedValueValidations[item.sku].couponValid">
								<v-tooltip>
									<template v-slot:activator="{ props }">
										<v-icon v-bind="props" color="orange">mdi-alert-circle</v-icon>
									</template>
									<b>{{$t('text.invalidCouponConfig2')}}</b>
									<br />
									<span v-for="error in addedValueValidations[item.sku]?.couponErrors" :key="error">
										<br /> <li> {{$t(`text.${error}`)}} </li>
									</span>
								</v-tooltip>
							</div>
						</template>
					</SectionTable>
				</div>
				<p v-if="addedValueItems?.length && salesChannelMismatch" class="helpText" style="color: orange">{{ $t('text.salesChannelMismatch') }}</p>
				<AddedValuesDialog :modelValue="[ addedValue ]" :linkedEntries="linkedEntries" @update:linkedEntries="$emit('update:linkedEntries', $event)" ref="editAddedValueDialog" :pricingType="pricingType" />
				<NewPackageDialog ref="newPackageDialog" v-if="addedValueProducts"
					v-model="model"
					:linkedEntries="linkedEntries"
					:productType="productType"
					@finished="afterNewPackageDialog"
				/>
				<!-- TODO: removed this? what was this for? we cant assign the computed..
					@mismatch="(val) => salesChannelMismatch = val"
				-->
			</FieldSet>
		</Section>
		<Section v-if="isRailawayPackage" id="transportProducts" open>
			<FieldSet id="transportationProducts" infoText="transportationProductsHelp">
				<AddButton @click="updateTransportProducts" buttonText="transportation" :update="true" />
				<SectionTable
					:columns="[
						'product',
						{ id: 'arrivalDepartureRestrictions', type: 'chip' },
						{ id: 'reduction', title: 'reductionPercent' },
						'validity',
						'purchase',
					]"
					:items="transportationItems"
					no-data-text="noTransportationProductsAdded"
					:hideFooter="true"
				/>
				<AddedValuesDialog v-model="transportationProducts" ref="transportProductsDialog" :linkedEntries="linkedEntries" @update:linkedEntries="$emit('update:linkedEntries', $event)" v-if="transportationProducts" :transportationView="true" :title="$t('text.transportProductsTitle')" />
			</FieldSet>
		</Section>

		<DataDialog v-if="addedValue" ref="editAddedValueSalesChannels"
			v-model="addedValue.fields.websites.de"
			:title="$t('text.salesChannels')"
			@update:modelValue="setSalesChannelsForAddedValue"
			:useWrap="true"
		>
			<template #content="{ wrap }">
				<!-- TODO: https://app.zeplin.io/project/604748c2bca41a3e66787ef4/screen/659fed1da16c1ec72109ce93 -->
				<div v-for="(scp, s) of salesChannelProductsLookup" :key="'scp' + s"
					style="display: flex; gap: 10px; align-items: center;"
				>
					<v-checkbox v-model="wrap.model" hide-details :label="scp.salesChannel.name" :value="{ website_id: scp.salesChannel.id, website_name: scp.salesChannel.name }" />
					{{ scp.products.length }} Products
				</div>
			</template>
		</DataDialog>
	</Step>
</template>

<script>
import Step from './Step.vue'
import Section from './Section.vue'
import FieldSet from './FieldSet.vue'
import Field from '../../../components/fields/Field.vue'
import Table from './Table.vue'
import SectionTable from './SectionTable.vue'
import DataDialog from '@/components/common/DataDialog.vue'
import AddButton from './AddButton.vue'
import Common from '@/mixins/Common.vue'
import NewPackageDialog from './dialogs/NewPackageDialog.vue'
import PackageSalesChannels from './PackageSalesChannels.vue'
import AddedValuesDialog from './added-values/AddedValuesDialog.vue'
import Alert from '@/components/common/Alert.vue'
import Application from '../Application.vue'
import SkuEditor from './SkuEditor.vue'
import Loading from 'vue-loading-overlay'
import AddButtonList from './AddButtonList.vue'
import IfFeatureFlag from "@/components/ifFeatureFlag/IfFeatureFlag.vue"
import ProductContentExecutive from '../../../../../shared/ProductContentExecutive'

export default {
	name: 'OffersStep',
	components: { Section, FieldSet, Field, Step, Table, SectionTable, AddButton, NewPackageDialog, PackageSalesChannels, DataDialog, AddedValuesDialog, Alert, Application, SkuEditor, Loading, AddButtonList, IfFeatureFlag },
	mixins: [ Common ],
	emits: ['update:linkedEntries'],
	props: {
		modelValue: Object,
		linkedEntries: Object,
		salesChannels: Array,
		products: Array,
	},
	data() {
		return {
			model: null,
			addedValue: null,
			productType: null,
			addedValueValidations: null,
		}
	},
	computed: {
		pricingType() {
			return this.model?.fields?.pricingType?.de
		},
		transportationProducts: {
			get() {
				const transportationProductIds = this.model?.fields?.addedValuesTransportation?.de ?? []
				const transportProducts = transportationProductIds.map(entry => this.linkedEntries[entry.sys.id])
				return transportProducts
			},
			set(value) {
			}
		},
		addedValueProducts: {
			get() {
				return this.model?.fields?.addedValues?.de ?? []
			},
			set(value) {
				this.model.fields.addedValues = { de: value }
			}
		},
		transportationItems() {
			return this.transportationProducts.map((item, index) => {
				// const item = this.linkedEntries[id]

				return {
					id: item.sys.id,
					product: item.fields.name[this.serviceLocale] ?? item.fields.name.de ?? '-',
					// TODO: how is All being taken in the table? does it check somehow every id entered in the dialog for restrictions against some list of stations?
					arrivalDepartureRestrictions: item.fields.stations?.de?.length === 0 ? '-' : item.fields.stations?.de?.join(', ') || this.$t('text.all'),
					reduction: item.fields.reductionPercent?.de > 0 ? item.fields.reductionPercent?.de + ' %' : '-',
					validity: `${(item.fields.validDaysBefore.de || item.fields.validDaysAfter.de) ? ((item.fields.transportType.de.toLowerCase() == 'outward' ? '-' : '+') + (item.fields.transportType.de.toLowerCase() == 'outward' ? item.fields.validDaysBefore.de : item.fields.validDaysAfter.de) + ' ' + this.$t('text.daysShort')) : '-'}`,
					purchase: item.fields.required?.de ? this.$t('text.mandatory') : this.$t('text.optional'),
				}
			})
		},
		addedValueItems() {
			return this.addedValueProducts.map(({ sys: { id } }, index) => {
				const item = this.linkedEntries[id]

				return {
					id: item.sys.id,
					product: item.fields.name[this.serviceLocale] ?? item.fields.name.de ?? '-',
					salesChannels: item.fields.sku?.de?.length === 1 ? item.fields.websites?.de?.length > 0 ? item.fields.websites?.de?.map(website => website.website_name)?.join(', ') : '-' : this.$t('text.multipleProducts'),
					status: item.fields.status?.de ?? '-',
					type: item.fields.validityType?.de ? this.$t(`text.validityType-${item.fields.validityType.de}`) : '-',
					reduction: item.fields.reductionPercent?.de > 0 ? item.fields.reductionPercent?.de + ' %' : '-',
					redemption: item.fields.maximumRedemptionCount?.de > 0 ? item.fields.maximumRedemptionCount?.de : '-',
					// Note: validityType can be (stay | more | fixed)
					validity: item.fields.validity?.de.toLowerCase() == 'yes' ? this.$t('text.any') : this.$t('text.duringStay'),
					purchase: item.fields.purchase?.de ?? '-',
					sortOrder: item.fields.sortOrder?.de ?? 0,
					sku: item.fields.sku?.de?.[0] ?? '-',
					isSkipass: item.fields.skipassProducts?.de?.length > 0,
				}
			})
		},
		salesChannelProductsLookup() {
			const r = {}
			if (!this.salesChannels) return r
			for (const salesChannel of this.salesChannels) {
				const products = this.products.filter(p => p.product_websites.some(website => website.product_website_id === salesChannel.id))
				r[salesChannel.id] = { salesChannel, products }
			}
			return r
		},
		salesChannelMismatch() {
			if (!this.model) return false

			const salesChannels = this.model.fields.websites.de.map(channel => channel.id)
			if (!this.linkedEntries) return

			let hasExtraSalesChannels = false
			this.model.fields.addedValues.de.forEach(({ sys: { id } }) => {
				const addedValue = this.linkedEntries[id]
				const addedValueSalesChannels = addedValue.fields.websites.de.map(channel => channel.website_id)
				const hasExtra = salesChannels.some(channel => !addedValueSalesChannels.includes(channel))
console.log(addedValue, addedValueSalesChannels, salesChannels, 'hasExtra', hasExtra)

				if (hasExtra) hasExtraSalesChannels = true
			})

			return hasExtraSalesChannels
		},
		isRailawaySkipassPackage() {
			// Since we want to get rid of the separate package type for OV-SKIPASS, we need to compute the logic. Therefore, we check if the package is a railaway package and if it has at least one added value item with a SKU starting with SKIPASS.
			return this.isRailawayPackage && this.addedValueItems?.some(({ isSkipass }) => isSkipass)
			// TODO: replace this statement with the one above when the SKIPASS package type is removed and all Railaway packages are OV packages
			// return this.model?.fields?.packageType?.de === 'package-ov-skipass'
		},
		isRailawayPackage() {
			return this.model?.fields?.packageType?.de === 'package-ov' || this.model?.fields?.packageType?.de === 'package-ov-skipass'
		},
		isRailawayClientWithAtLeastOneAV() {
			// Since we want to get rid of the separate package type for OV-SKIPASS, we need to compute the logic. Therefore, if a package is a railaway package and it has at least one AV, we disable the SKIPASS option in the add button list, since an OV Package cannot have more than one skipass AV, but it can have multiple other AVs.
			return this.isRailawayPackage && this.addedValueItems?.length > 0
		},
	},
	watch: {
		model: {
			deep: true,
			handler(n) { this.$emit('update:modelValue', n); this.validateCouponConfig() },
		},
		modelValue(n) { this.model = n },
	},
	methods: {
		updateTransportProducts() {
			this.$refs.transportProductsDialog.open()
		},
		updateAddedValueProducts() {
			this.$refs.newPackageDialog.open()
		},
		editAddedValueChannels(item) {
			// TODO: server call to update the sales channels of the underlying product of the added value
			this.addedValue = this.linkedEntries[item.id]
			console.log('editAddedValueChannels', this.addedValue)
			this.$nextTick(() => {
				this.$refs.editAddedValueSalesChannels.open()
			})
		},
		async deleteAddedValue(addedValue) {
			this.loading = true
			const packageType = this.model.fields.packageType?.de
			await this.$httpDelete(`/packageTravel/package/${this.modelValue.sys.id}/addedValue/${addedValue.id}?clientId=${this.$store.state.selectedClient.sys.id}`)
			this.addedValueProducts = this.addedValueProducts.filter((item) => item.sys.id !== addedValue.id)
			delete this.linkedEntries[addedValue.id]
			this.loading = false
		},
		editAddedValue(item) {
			this.addedValue = this.linkedEntries[item.id]
			// TODO: revise this when the linked entries has the sys and fields included
			this.addedValue.id = item.id
			console.log('editAddedValue', this.addedValue)
			this.$nextTick(() => {
				this.$refs.editAddedValueDialog.open()
			})
		},
		async afterNewPackageDialog(addedValues) {
			// TODO: add added values to our model array
			console.log('afterNewPackageDialog ', addedValues)
			// we reset the selected array in case it was auto-modified by the MRW configurable dialog
			this.$refs.newPackageDialog.selected = []
			this.$refs.newPackageDialog.close()
		},
		async setSalesChannelsForAddedValue() {
			const clientId = this.$store.state.selectedClient.sys.id
			const packageType = this.model.fields.packageType?.de
			this.$httpPost(`/packageTravel/addedValue/${this.addedValue.sys.id}/websites?clientId=${clientId}&packageType=${packageType}`, {
				websiteIds: this.addedValue.fields.websites.de.map(website => website.website_id),
			})
		},
		async moveUp(item) {
			const index = this.addedValueProducts.findIndex(({ sys: { id } }) => id === item.id)
			if (index <= 0) return

			const prev = this.addedValueProducts[index - 1]

			this.swapItems(this.addedValueProducts, index, index - 1)
			this.swapSortOrder(this.linkedEntries[item.id], this.linkedEntries[prev.sys.id])

			item.sortOrder = this.linkedEntries[item.id].fields.sortOrder.de

			await this.upsertAddedValuesOrder(item.id, item.sortOrder, 'moveUp')
		},
		swapItems(array, index1, index2) {
			[array[index1], array[index2]] = [array[index2], array[index1]]
		},
		swapSortOrder(entry1, entry2) {
			[entry1.fields.sortOrder.de, entry2.fields.sortOrder.de] = [entry2.fields.sortOrder.de, entry1.fields.sortOrder.de]
		},
		async moveDown(item) {
			const index = this.addedValueProducts.findIndex(({ sys: { id } }) => id === item.id)
			if (index >= this.addedValueProducts.length - 1) return

			const next = this.addedValueProducts[index + 1]

			this.swapItems(this.addedValueProducts, index, index + 1)
			this.swapSortOrder(this.linkedEntries[item.id], this.linkedEntries[next.sys.id])

			item.sortOrder = this.linkedEntries[item.id].fields.sortOrder.de

			await this.upsertAddedValuesOrder(item.id, item.sortOrder, 'moveDown')
		},
		async upsertAddedValuesOrder(addedValueId, sortOrder, mode) {
			this.loading = true
			try {
				addedValueId = addedValueId.replace('AV_', '')
				const packageId = this.$route.params.id
				const payload = {
					clientId: this.$store.state.selectedClient.sys.id,
					addedValueOrder: { addedValueId, sortOrder },
					mode: mode,
				}
				await this.$httpPut(`/packageTravel/package/${packageId}/addedValuesOrder`, payload)
			} catch (error) {
				console.error('Failed to update added values order:', error)
			}
			this.loading = false
		},
		validateCouponConfig() {
			if (!this.model) return
			let skus = []
			this.model.fields.addedValues.de.forEach(({ sys: { id } }) => {
				const addedValue = this.linkedEntries[id]
				// Only COUPON AVs that are inclusive need to be validated
				if (addedValue.fields.sku?.de?.[0]?.startsWith('COUPON') && addedValue.fields.type.de == 'inclusive' && !addedValue.addl) {
					skus.push(addedValue.fields.sku.de[0])
				}
			})
			this.checkCouponConfig(skus)
		},
		async checkCouponConfig(skus) {
			try {
				if (!skus) return
				const executive = new ProductContentExecutive({ clientId: this.$store.state.selectedClient.sys.id }, this)
				this.addedValueValidations = await executive.validateAddedValueProductsForSkus(skus)
				console.log('addedValueValidations', this.addedValueValidations)
// TODO: map to ui

/*				// res is an array of objects: { sku: string, valid: boolean }[]
				const res = await this.$httpGet(`/packageTravel/package/couponConfig?skus=${skus}&clientId=${clientId}&packageId=${packageId}`)
				this.addedValueProducts.map(({ sys: { id } }, index) => {
					const item = this.linkedEntries[id]
					const found = res.find(({ sku }) => sku === item.fields.sku.de[0])
					if (found) {
						item.addl = { ... item.addl, validConfig: found.valid }
					}
				})*/
			}
			catch (error) {
				console.error('Failed to check coupon config:', error)
			}
		},
		setProductTypeAndOpenAvDialog(productType) {
			this.productType = productType
			this.$nextTick(() => {
				this.updateAddedValueProducts()
			})
		},
	},
	async mounted() {
		this.model = this.modelValue
	},
}
</script>

<style scoped>
.loading { pointer-events: none; }
.mismatch { border: 2px solid orange; }
</style>
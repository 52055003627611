<template>
	<div style="display: flex; align-items: center; gap: 10px;">
		<Field
			v-if="modelValue && options?.length"
			:disabled="disabled"
			typeName="Ticket"
			fieldName="ageGroup"
			:modelValue="modelValue"
			:options="options"
			:showTitle="false"
			dataCy="age-group-dropdown"
		/>
		<v-btn v-if="!isRailawayPackage && this.editable" @click="$refs.manage.open()" data-cy="edit-age-groups" variant="text" icon>
			<v-icon size="small">mdi-pencil</v-icon>
		</v-btn>
		
		<div v-if="showDetails" class="details">
			{{ formatAgeRestriction(modelValue?.de) }}
		</div>

		<StepperDialog ref="manage" v-model="model" v-model:stepId="step" group="AgeGroupStep"
			:order="[ 'ageGroups', 'configuration' ]"
			:onConfirm="handleConfirm"
			:onCancel="handleCancel"
			:disableConfirmButton="disableConfirmButton"
		>
			<Step id="ageGroups" group="AgeGroupStep" icon="mdi-format-list-bulleted" class="StepWithoutStepper">
				<FieldSet id="ticketTypes" style="padding-bottom: 20px;"></FieldSet>
				<br/>
				<FieldSet id="globalSetting" mdiIcon="earth" style="padding-bottom: 20px;"></FieldSet>
				<AddButton @click="addAgeGroup" buttonText="ageGroup" dataCy="add-age-group" />
				<SectionTable
					class="table"
					:items="options"
					:hideFooter="true"
					:columns="[ 'label', 'ageGroup', { id: '', type: 'options', width: '0%' } ]"
					:options="[
						{ label: $t('text.edit'), icon: 'mdi-pencil', action: 'edit' },
						{ label: $t('text.delete'), icon: 'mdi-delete', action: 'delete' },
					]"
					no-data-text="noAgeGroups"
					@click-item="editAgeGroup"
					@option-edit="editAgeGroup"
					@option-delete="item => toggleDeleteDialog(true, item)"
					>
				</SectionTable>
			</Step>
			<Step id="configuration" class="StepWithoutStepper" group="AgeGroupStep"
				:forceStatus="!!ageGroup ? 'complete' : null"
				icon="mdi-cog"
				:disabled="!ageGroup"
				confirmStep="ageGroups"
			>
				<v-progress-linear indeterminate v-show="loading" color="green" height="8"></v-progress-linear>
				<template v-if="ageGroup">
					<div :class="{ overlayed: loading }">
						<TranslateableField typeName="AgeGroup" fieldName="label" v-model="ageGroup.label" @update:modelValue="setTempKey"
							:fieldLocale="$store.state.activeTranslation" :locales="displayedLocales"
							dataCy="age-group-name-input"
						/>
						<br/>
						<FieldSet id="generalSettingsTranslationWizard" style="padding-bottom: 20px;"></FieldSet>
						<FieldSet id="age">
							<div class="row" :class="{ disabled: $store.state.activeTranslation != 'de' }">
								<div>
									<p>{{$t('text.type')}}</p>
									<v-select variant="outlined" density="compact"
										data-cy="age-group-age-type-selection"
										style="max-width: 220px;"
										v-model="ageGroupType"
										@update:modelValue="resetAgeGroupFromTo"
										:placeholder="$t('text.placeHolderAgeGroupType')"
										:items="ageGroupTypes"
										:item-title="item => $t(`text.${item?.label ?? 'placeHolderAgeGroupType'}`)"
									/>
								</div>

								<Field v-if="ageGroupType === 'ageStages'" typeName="AgeGroup" fieldName="ageFrom" v-model="ageGroup.ageFrom" dataCy="age-from-input"/>
								<Field v-else-if="ageGroupType === 'exactDates'" typeName="AgeGroup" fieldName="referenceFrom" v-model="ageGroup.referenceFrom" :max="ageGroup.referenceUntil.de" dataCy="age-from-input"/>
								<Field v-if="ageGroupType === 'ageStages'" typeName="AgeGroup" fieldName="ageTo" v-model="ageGroup.ageTo" dataCy="age-to-input"/>
								<Field v-else-if="ageGroupType === 'exactDates'" typeName="AgeGroup" fieldName="referenceUntil" v-model="ageGroup.referenceUntil" :min="ageGroup.referenceFrom.de" dataCy="age-to-input"/>
							</div>
						</FieldSet>
					</div>
				</template>
			</Step>

			<template #sidebar v-if="step=='configuration'">
				<LanguageSidebar :checkIfHasMissingTranslations="checkIfHasMissingTranslations" />
			</template>
			
			<!-- Confirm Delete -->
			<Dialog ref="deleteDialog"
				:confirmLabel="$t('text.delete')"
				:cancelLabel="$t('text.cancel')"
				:confirm-handler="onDeleteConfirmed"
				:cancel-handler="onDeleteCancelled"
				:showClose="false"
				:isDelete="true"
				:title="$t('text.delete')"
				:height="'360px'"
				:width="'540px'">
				<template #content>
					<v-row justify="center" align="center" style="padding:10px">
						<v-row justify="center" align="center" style="padding: 10px">
							<v-col class="warning-icon-col">
								<img class="warningDialogImage" src="@/assets/icons/icon-warning.svg" />
							</v-col>
							<v-col class="text-col">
								<div class="column">
									<span><b>{{$t('text.confirmDeleteAgeGroupHeader')}}</b></span>
									<span class="dialogText">{{$t('text.confirmDeleteAgeGroupText')}}</span>
								</div>
							</v-col>
						</v-row>
					</v-row>
				</template>
			</Dialog>
		</StepperDialog>
	</div>
</template>

<script lang="ts">
import SectionTable from '../../views/applications/packageDesigner/SectionTable.vue'
import LanguageSidebar from '../common/LanguageSidebar.vue'
import Field from './Field.vue'
import { clear, load } from './loader'
import Common from '../../mixins/Common.vue'
import StepperDialog from '../common/StepperDialog.vue'
import Step from '../../views/applications/packageDesigner/Step.vue'
import AddButton from '../../views/applications/packageDesigner/AddButton.vue'
import TranslateableField from './TranslateableField.vue'
import LanguagesNavigation from '../../mixins/LanguagesNavigation.vue'
import IfFeatureFlag from '../ifFeatureFlag/IfFeatureFlag.vue'
import FieldSet from '../../views/applications/packageDesigner/FieldSet.vue'
import Dialog from '../common/Dialog.vue'
import ChildErrorReceiver from '../../views/applications/packageDesigner/ChildErrorReceiver.vue'
import eventBus from '@/utils/eventBus.js'

// TODO: maybe move loading this into the package so it can be included with the progress indicator
//       here we would then only inject that data.
export async function loadDimensions(_this: any, clientId, force = false): Promise<{
		duration: { options: any[] }
		ageGroup: { options: any[] }
		skipassAgeGroupMapping: { options: any[] }
	}> {
	const key = 'DIMS-' + clientId
	if (force) clear(key)
	return await load(key, async () => {
		try {
			const r = await _this.$httpGet(`/packageTravel/dimensions?clientId=${clientId}`)
			r.ageGroup.options.sort((a, b) => (a.label[_this.serviceLocale] ?? '').localeCompare(b.label[_this.serviceLocale]))
			r.duration.options.sort((a, b) => (a.label[_this.serviceLocale] ?? '').localeCompare(b.label[_this.serviceLocale]))
			return r
		}
		catch (error) {
			console.error(error)
		}
	})
}

export default {
	components: { Field, LanguageSidebar, SectionTable, StepperDialog, Step, AddButton, TranslateableField, IfFeatureFlag, FieldSet, Dialog },
	mixins: [Common, LanguagesNavigation, ChildErrorReceiver],
	inject: ['injectedData'],
	props: {
		modelValue: Object, // {de: string }
		editable: { type: Boolean, default: true },
		showDetails: { type: Boolean, default: true },
		disabled: { type: Boolean, default: false },
	},
	data: () => ({
		options: [],
		loading: false,
		ageGroup: null,
		step: null,
		ageGroupType: '',
		ageGroupTypes: [
			{ label: 'ageStages', value: 'ageStages' },
			{ label: 'exactDates', value: 'exactDates' },
		],
		itemAboutToDelete: null,
	}),
	computed: {
		displayedLocales() {
			return this.languageNavigationItems?.reduce((locales, { code }) => {
				if (code !== "all" && (this.$store.state.activeTranslation === "all" || this.$store.state.activeTranslation === code)) {
					locales.push(code)
				}
				return locales
			}, [])
		},
		disableConfirmButton() {
			if ((!this.ageGroup || !this.ageGroup.ageFrom?.de || !this.ageGroup.ageTo?.de) && (!this.ageGroup || !this.ageGroup.referenceFrom?.de || !this.ageGroup.referenceUntil?.de)) return true

			// only German should be filled in, which will fill in the default store in PEAK. Other languages are optional and will update the other stores.
			return this.ageGroup.label.de === ''
		},
		isRailawayPackage() {
			return this.injectedData.packageDetails?.fields.packageType?.de == 'package-ov' || this.injectedData.packageDetails?.fields.packageType?.de == 'package-ov-skipass'
		},
	},
	watch: {
		// reload options when locale changes. Otherwise we would have to reload the page to see the changes.
		serviceLocale() {
			this.loadOptions()
		},
		model() {
			this.$emit("update:modelValue", this.model)
		},
		modelValue(modelValue) {
			this.model = modelValue
		},
		// TODO: for some reason childErrors are not being triggered here when there are validation errors in the date model-driven field
		childErrors: {
			handler() {
				console.log('childErrors in age group', this.childErrors)
			},
			deep: true,
		},
	},
	methods: {
		async loadOptions(force = false) {
			this.loading = true
			const clientId = this.$store.state.selectedClient.sys.id
			const r = await loadDimensions(this, clientId, force)
			if (r) {
				this.options = r.ageGroup.options
				// ageGroup does not exist in the model, we only need it to show the ageFrom and ageTo in the same column in the table to match the designs
				// we also need to add an id property because otherwise the Field model-driven component will set a new de object on the key property even if it is already set
				this.options.forEach(option => {
					if (option.ageFrom.de && option.ageTo.de) {
						option.ageGroup = option.ageFrom.de + ' - ' + option.ageTo.de,
						option.id = option.key?.de ?? option.key
					}
					else {
						option.ageGroup = option.referenceFrom.de + ' - ' + option.referenceUntil.de,
						option.id = option.key?.de ?? option.key
					}
				})
			}
			this.loading = false
		},
		formatAgeRestriction(key: string) {
			const ag = this.options?.find(ageGroup => ageGroup.key?.de == key || ageGroup.key == key)
			if (!ag) return ''
			if (ag.ageFrom.de && ag.ageTo.de) {
				return ag.ageFrom.de + ' - ' + ag.ageTo.de
			} else if (ag.referenceFrom.de && ag.referenceUntil.de) {
				return ag.referenceFrom.de + ' - ' + ag.referenceUntil.de
			} else {
				return ''
			}
		},
		checkIfHasMissingTranslations(locale) {
			return !this.ageGroup?.label?.[locale]
		},
		addAgeGroup() {
			this.ageGroup = { id: '', key: { de: '' }, label: { de: '', en: '', fr: '', it: '', nl: '' }, ageFrom: { de: '' }, ageTo: { de: '' } }
			this.$refs.manage.show('configuration')
		},
		editAgeGroup(item) {
			console.log('editAgeGroup', item)
			this.ageGroup = JSON.parse(JSON.stringify(item))
			// guess the ageGroupType based on the values of ageFrom and ageTo
			this.guessAgeGroupType()
			console.log('editAgeGroup', this.ageGroup)
			this.$refs.manage.show('configuration')
		},
		deleteAgeGroup() {
			try {
				const item = this.itemAboutToDelete
				console.log('deleteAgeGroup', item)
				const index = this.options.findIndex(option => option.id == item.id)
				this.options.splice(index, 1)
				// persist the change
				const payload = {
					clientId: this.$store.state.selectedClient.sys.id,
					ageGroups: this.options
				}
				this.$httpPost('/packageTravel/ageGroups', payload)
				// TODO: what do we do for the tickets that already have this duration selected? Check in the backend and throw an error that it cannot be deleted or remove it from the existing tickets as well and assign a fallback duration as a default?
			}
			catch (e) {
				console.error(e)
			}
		},
		handleCancel() {
			this.ageGroup = null
		},
		async handleConfirm() {
			console.log('handleConfirm', this.ageGroup)
			if (this.step == 'configuration') {
				try {
					if (this.options.some(option => option.key.de === this.ageGroup.key.de && option.id !== this.ageGroup.id)) {
						console.log('duplicate key found')
						eventBus.$emit('addToastMessage', this.$t('text.duplicateAgeGroupKey'), 'error')
						this.ageGroup = null
						return false
					}
					this.loading = true
					// TODO: brimar: if this.ageGroup is not set, i believe we could just exit out of here - blend?
					// if (!this.ageGroup) return
					const options = [ ...this.options ]
					if (this.ageGroup) {
						if (this.ageGroup.id) {
							const index = options.findIndex(option => option.id === this.ageGroup.id)
							if (index !== -1)
								options[index] = this.ageGroup
							else
								options.push(this.ageGroup)
						}
						this.ageGroup.id = this.ageGroup.key.de
					}
					const ageGroups = JSON.parse(JSON.stringify(options))
					const clientId = this.$store.state.selectedClient.sys.id
					await this.$httpPost('/packageTravel/ageGroups', { clientId, ageGroups })
					eventBus.$emit('addToastMessage', this.$t('text.changesSuccessfullyProcessed'), 'success')
					await this.loadOptions(true)
					this.ageGroup = null
				}
				catch (e) {
					this.loading = false
					console.error(e)
					eventBus.$emit('addToastMessage', this.$t('text.errorProcessingChanges'), 'error')
				}
			}
		},
		guessAgeGroupType() {
			// if the age from and age to are in the following format: 2017-12-31 then we assume that the ageGroupType is exactDates
			// const dateMatchRegex = /^\d{4}-\d{2}-\d{2}$/
			// this.ageGroupType = dateMatchRegex.test(this.ageGroup.ageFrom.de) && dateMatchRegex.test(this.ageGroup.ageTo.de) ? 'exactDates' : 'ageStages'
			// if referenceFrom and to are filled then we assume that the ageGroupType is exactDates
			console.log('guessAgeGroupType', this.ageGroup)
			this.ageGroupType = this.ageGroup.referenceFrom.de && this.ageGroup.referenceUntil.de ? 'exactDates' : 'ageStages'
		},
		resetAgeGroupFromTo() {
			// we need to reset the ageFrom and ageTo fields when the ageGroupType changes otherwise the Date picker will not work correctly with a value that is not a date
			this.ageGroup.ageFrom = { de: '' }
			this.ageGroup.ageTo = { de: '' }
			this.ageGroup.referenceFrom = { de: '' }
			this.ageGroup.referenceUntil = { de: '' }
		},
		onDeleteConfirmed() {
			this.toggleDeleteDialog(false, null)
			this.deleteAgeGroup()
			return true
		},
		onDeleteCancelled() {
			this.toggleDeleteDialog(false, null, true)
		},
		toggleDeleteDialog(val, item, reset = false) {
			this.$refs.deleteDialog.show = val
			// TODO: I did not like this solution but I could not find a better one. The item is not passed to the dialog so we need to store it in a variable to be able to access it in the deleteAgeGroup method
			if (item) this.itemAboutToDelete = item
			if (reset) this.itemAboutToDelete = null
		},
		setTempKey(value) {
			// since we have a client-side cache of the options we need to update the key in the options array as well in case the user decides to immediately update the newly created option. For persisted options, this is set automatically in the backend
			this.ageGroup.key = this.generateAgeGroupKey(value)
		},
		generateAgeGroupKey(label) {
			if (label === undefined || label === null) return ''
			if (typeof label === 'object') label = label.de
			if (!(typeof label === 'string')) return ''
			return label.toLowerCase().replace(/[^a-z0-9]/g, '')
		}
	},
	async mounted() {
		this.model = this.modelValue
		await this.loadOptions()
	},
}
</script>

<style scoped lang="scss">
.disabled {
	pointer-events: none !important;
	opacity: 0.5 !important;
}
.overlayed {
	pointer-events: none;
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(255, 255, 255, 0.5);
		z-index: 99;
	}
}

.row {
	display: flex;
	gap: 10%;
	flex-grow: 1;
	padding-top: 10px;
}

.column {
	display: flex;
	flex-direction: column;
}

.StepWithoutStepper {
	margin-top: 0px;
	position: relative;
}
.details { white-space: nowrap; }
</style>
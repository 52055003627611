<script lang="ts">
import { AV_TYPES } from '@/constants/addedValueOffers'

export default {
	methods: {
		mapAddedValueFields(avType) {
			return {
				reductionPercent: { de: this.getDefaultReductionPercent(avType) },
				directPurchase: { de: this.getDefaultDirectPurchase(avType) },
				type: { de: avType },
				seasonalDiscounts: { de: [] },
				hasContingent: { de: 'n' },
				maximumRedemptionCount: { de: null },
				validityType: { de: this.getDefaultValidityType(avType) },
				validDaysBefore: { de: 0 },
				validDaysAfter: { de: 0 },
				showPrice: { de: true },
				marketingText: { de: null },
				roundingPrecision: { de: this.getDefaultRoundingPrecision() },
				roundingMode: { de: this.getDefaultRoundingMode() },
				purchase: { de: [AV_TYPES.INCLUSIVE, AV_TYPES.FIXED].includes(avType) ? 'booking_only' : 'booking' },
				required: { de: this.getDefaultRequired(avType) },
			}
		},
		getDefaultRequired(avType) {
			return avType !== AV_TYPES.OPTIONAL
		},
		getDefaultReductionPercent(avType) {
			if (avType === AV_TYPES.INCLUSIVE) return 100
			if ([AV_TYPES.FIXED, AV_TYPES.OPTIONAL].includes(avType)) return null

			return 0
		},
		getDefaultDirectPurchase(avType) {
			if (avType === AV_TYPES.INCLUSIVE) return true

			return false
		},
		getDefaultRoundingPrecision() {
			if (this.type === 'package-general') return null

			return 1
		},
		getDefaultRoundingMode() {
			if (this.type === 'package-general') return 'round_half_up'

			return 'round_always_up'
		},
		getDefaultValidityType(avType) {
			if ([AV_TYPES.INCLUSIVE, AV_TYPES.OV].includes(avType)) return 'fixed'
			else if ([AV_TYPES.FIXED, AV_TYPES.OPTIONAL].includes(avType)) return 'variable'

			return null
		},
	},
}
</script>

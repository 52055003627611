<template>
<!-- TODO: what to do with the messages?
		:error="sectionMessage.error"
		:message="sectionMessage.message"
-->
	<Disclosure ref="disclosure"
		class="Section"
		:class="{ disabled }"
		:title="$t('text.' + id + 'Title')"
		:data-cy="id"
		:eager="eager"
		:lock="autoLock ? !userMayEditFields : false"
		:error="!disabled && !!childErrorMessage"
		:message="childErrorMessage"
	>
		<div style="display: flex; flex-direction: column; gap: 32px">
			<slot></slot>
		</div>
	</Disclosure>
</template>

<script>
import Disclosure from '../../../components/common/Disclosure.vue'
import Common from '../../../mixins/Common.vue'
import eventBus from '@/utils/eventBus.js'
import ChildErrorReceiver from './ChildErrorReceiver.vue'

export default {
	name: 'Section',
	components: { Disclosure },
	mixins: [ Common, ChildErrorReceiver ],
	props: {
		id: String,
		eager: { type: Boolean, default: true },
		autoLock: { type: Boolean, default: true },
		open: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
	},
	data: () => ({
		status: 'incomplete',
		stepId: null,
	}),
	watch: {
		childErrorMessage(n) {
			this.status = n ? 'error' : 'incomplete'
		},
	},
	methods: {
		show(id) {
			if (id != this.id) return
			if (!this.$refs.disclosure) return console.error('Section.show: no disclosure ref')
			this.$refs.disclosure.open()
			// we do it later because the disclosure may take some time to render
			window.setTimeout(() => {
				const el = this.$refs.disclosure.$el
				const y = el.getBoundingClientRect().top + window.pageYOffset - 135 // top header + step bar + 10
				console.log('ST', el, y)
				window.scrollTo({ top: y, behavior: 'smooth' })
			}, 200)
		},
		register() {
			eventBus.$emit('section-register', this)
		},
	},
	
	mounted() {
		// find the parent step for this section
		let step = this.$el
		while (step && step.classList.contains('Step')) step = step.parentNode
		// remember the parent step id for later reference (see SectionIndex)
		if (step) this.stepId = step.id

		if (this.open) {
			this.$refs.disclosure.open()
		}
		eventBus.$on('section-show', this.show)
		this.register()
		eventBus.$on('section-cfr', this.register)
	},
	unmounted() {
		eventBus.$off('section-show', this.show)
		eventBus.$off('section-cfr', this.register)
	},
}
</script>

<style scoped>
.Section.disabled { opacity: 0.5; }
</style>
<template>
	<div class="optional-items">
		<div @click="$refs.optionalSettingsDialog.open()"
			style="background: #fafafa; border-bottom: 1px solid #eee; border-top: 1px solid #eee; color: gray; font-size: smaller; margin-bottom: 20px; padding: 8px 24px; margin: -8px -24px 20px -24px; cursor: pointer;"
		>
			<v-icon>mdi-cog</v-icon>
			{{ $t('text.additionalSettingsCustomize') }}
		</div>

		<Optional id="purchaseType" v-model="optionalSettings" :startEnabled="selectedAvType === AV_TYPES.OPTIONAL">
			<Field typeName="AddedValue" fieldName="purchase" v-model="modelValue.fields.purchase" @update:modelValue="onUpdatePurchaseType" required :title="$t('text.purchaseType')" :infoText="$t('text.purchaseTypeHelpText')"
				:disabled="[AV_TYPES.INCLUSIVE, AV_TYPES.FIXED].includes(selectedAvType)" />
		</Optional>

		<div>
			<Optional id="seasonalDiscounts" v-model="optionalSettings" :autoShow="autoShowSeasonalDiscounts">
				<FieldSet id="seasonalDiscounts" class="pt-6">
					<AddButton @click="addSeasonalDiscount" buttonText="seasonalDiscount" />
					<EditableTable
						class="my-4"
						v-model="modelValue.fields.seasonalDiscounts.de"
						:columns="[
							'startDate',
							{ id: 'endDate', min: (item) => item.fields.startDate?.de },
							'reductionPercent',
						]"
						typeId="SeasonalDiscount"
						no-data-text="noSeasonalDiscountsSet"
					>
						<template #deleteItem="{ item }">
							<v-icon class="deleteIcon" @click="(action) => handleOptionClick(item.sys.id, 'delete')">mdi-delete</v-icon>
						</template>
					</EditableTable>
				</FieldSet>
			</Optional>
			<Optional id="reductionSettings" v-model="optionalSettings" :autoShow="autoShowReductionSettings">
				<FieldSet id="reductionSettings">
					<Field typeName="AddedValue" fieldName="reductionPercent" v-model="modelValue.fields.reductionPercent" required :disabled="selectedAvType === AV_TYPES.INCLUSIVE" />
				</FieldSet>
			</Optional>
		</div>
		<Optional id="contingentSettings" v-model="optionalSettings" :autoShow="modelValue.fields.maximumRedemptionCount?.de > 0" :startEnabled="!!modelValue.fields.maximumRedemptionCount?.de">
			<FieldSet id="contingentSettings">
				<div class="d-flex">
					<Field typeName="AddedValue" fieldName="maximumRedemptionCount" v-model="modelValue.fields.maximumRedemptionCount"/>
					<Field v-if="selectedAvType === AV_TYPES.OPTIONAL && modelValue.fields.purchase.de === 'wallet'"
						class="mt-6" typeName="AddedValue" fieldName="hasContingent" v-model="modelValue.fields.hasContingent"
						:showTitle="false" :disabled="!(selectedAvType === AV_TYPES.OPTIONAL && modelValue.fields.purchase.de === 'wallet')"
						:options="[
							{ id: 'y', label: $t('text.perStay') },
							{ id: 'd', label: $t('text.perConsumptionDay') },
						]"
					/>
				</div>
			</FieldSet>
		</Optional>
		<!-- TODO: this should actually be a field -->
		<Optional id="validitySettings" v-model="optionalSettings" :autoShow="modelValue.fields.validDaysBefore?.de || modelValue.fields.validDaysAfter?.de" :startEnabled="true">
			<FieldSet id="validitySettings" >
				<Field typeName="AddedValue" fieldName="validityType" v-model="modelValue.fields.validityType" />
				<div style="display: flex; gap: 10px;" :class="{
					disabled: modelValue.fields.validityType?.de !== 'variable+',
				}">
					<Field typeName="AddedValue" fieldName="validDaysBefore" v-model="modelValue.fields.validDaysBefore" style="flex: 1;" :disabled="modelValue.fields.validityType?.de !== 'variable+'" />
					<Field typeName="AddedValue" fieldName="validDaysAfter" v-model="modelValue.fields.validDaysAfter" style="flex: 1;" :disabled="modelValue.fields.validityType?.de !== 'variable+'" />
				</div>
			</FieldSet>
		</Optional>

		<FieldSet id="additionalSettings" v-show="optionalSettings?.directPurchase || optionalSettings?.showPrice || optionalSettings?.marketingText || optionalSettings?.mandatory">
			<Optional id="directPurchase" v-model="optionalSettings" :autoShow="selectedAvType === AV_TYPES.INCLUSIVE" :startEnabled="modelValue.fields?.directPurchase?.de">
				<Field class="mb-4" typeName="AddedValue" fieldName="directPurchase" v-model="modelValue.fields.directPurchase"
					:disabled="[AV_TYPES.INCLUSIVE, AV_TYPES.FIXED, AV_TYPES.OPTIONAL].includes(selectedAvType)" />
			</Optional>

			<Optional id="showPrice" v-model="optionalSettings" :startEnabled="modelValue.fields.showPrice?.de">
				<Field class="mb-4" typeName="AddedValue" fieldName="showPrice" v-model="modelValue.fields.showPrice" :infoText="$t('text.showPriceInfoText')" />
			</Optional>

			<Optional id="mandatory" v-model="optionalSettings" :startEnabled="modelValue.fields.required?.de">
				<Field class="mb-4" typeName="AddedValue" :title="$t('text.mandatory')" fieldName="required" v-model="modelValue.fields.required"
					:infoText="$t('text.mandatoryInfoText')" :disabled="[AV_TYPES.INCLUSIVE, AV_TYPES.FIXED, AV_TYPES.OPTIONAL].includes(selectedAvType)" />
			</Optional>

			<Optional id="marketingText" v-model="optionalSettings" :startEnabled="modelValue.fields.marketingText?.de?.length">
				<Field typeName="AddedValue" fieldName="marketingText" v-model="modelValue.fields.marketingText" :infoText="$t('text.marketingTextInfoText')" />
			</Optional>
		</FieldSet>

		<div v-if="modelValue.fields.skipassProducts?.de?.length"
			style="margin-top: 10px; display: flex; gap: 5px; flex-direction: column; color: gray; font-size: smaller;"
		>
			<!--
			<div v-for="skipassProduct, s of modelValue.fields.skipassProducts?.de ?? []" :key="s">
				{{ skipassProduct }}
			</div>
			-->
			<SkuMultiSelect
				v-model="modelValue.fields.skipassProducts.de"
			/>
		</div>
		<DataDialog v-model="optionalSettings" :title="$t('text.customizeOptions')" ref="optionalSettingsDialog" :useWrap="true">
			<template #content="{ wrap }">
				<!-- TODO: the model is wrong here, we need a separate model per item.
							probably this whole dialog should actually move into the itemHead instead.
				-->
				<OptionalSettings v-model="wrap.model" @update:modelValue="wrap['model'] = $event" />
			</template>
		</DataDialog>
	</div>
</template>

<script>
import DataDialog from '../../../../components/common/DataDialog.vue'
import Field from '../../../../components/fields/Field.vue'
import FieldSet from '../FieldSet.vue'
import Optional from '../Optional.vue'
import OptionalSettings from '../OptionalSettings.vue'
import Table from '../../../../components/common/tables/Table.vue'
import EditableTable from '../EditableTable.vue'
import AddButton from '../AddButton.vue'
import TagsField from '../../../../components/fields/TagsField.vue'
import SkuMultiSelect from '../SkuMultiSelect.vue'
import { AV_TYPES } from '@/constants/addedValueOffers.ts'

export default {
	components: { DataDialog, Optional, FieldSet, Field, OptionalSettings, Table, EditableTable, AddButton, TagsField, SkuMultiSelect },
	props: {
		modelValue: Object,
		pricingType: String,
		selectedAvType: String,
		isRailawayPackage: Boolean,
	},
	data() {
		return {
			optionalSettings: {},
		}
	},
	computed: {
		autoShowReductionSettings() {
			if (this.pricingType === 'fixed' && !this.isRailawayPackage) return false
			if (this.pricingType === 'sum' && !this.isRailawayPackage) return [AV_TYPES.FIXED, AV_TYPES.OPTIONAL].includes(this.selectedAvType)
			return this.modelValue.fields.reductionPercent?.de > 0
		},
		autoShowSeasonalDiscounts() {
			if (this.pricingType === 'sum' && !this.isRailawayPackage) return [AV_TYPES.FIXED, AV_TYPES.OPTIONAL].includes(this.selectedAvType)
			return this.modelValue.fields.seasonalDiscounts?.de?.length > 0
		},
	},
	methods: {
		onUpdatePurchaseType(value) {
			this.modelValue.fields.hasContingent.de = value?.de === 'wallet' ? 'y' : 'n'
			this.modelValue.fields.maximumRedemptionCount.de = value?.de === 'wallet' ? 1 : null
			this.optionalSettings.contingentSettings = value?.de === 'wallet'
		},
		addSeasonalDiscount() {
			const seasonalDiscount = this.seasonalDiscountFactory()
			this.modelValue.fields.seasonalDiscounts.de.push(seasonalDiscount)
		},
		handleOptionClick(id, action) {
			if (action === 'delete') {
				const index = this.modelValue.fields.seasonalDiscounts.de.findIndex(item => item.sys.id === id)
				if (index >= 0) {
					this.modelValue.fields.seasonalDiscounts.de.splice(index, 1)
				}
			}
		},
		seasonalDiscountFactory() {
			return {
				sys: { id: '' },
				fields: {
					startDate: { de: null },
					endDate: { de: null },
					reductionPercent: { de: 0 },
				},
			}
		},
	},
	setup() {
		return { AV_TYPES }
	},
}
</script>

<style scoped>
.optional-items > * {
	/* TODO: this is breaking the left border and each optional item now has its own border. Is that fine? */
	padding-bottom: 20px;
}
</style>
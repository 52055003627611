<template>
	<div class="FlushPeakCache">
		<v-tooltip width="400px">
			<template v-slot:activator="{ props }">
				<v-btn v-bind="props" style="float:right" class="mt-4" variant="outlined" :disabled="loading" :loading="loading" @click="showWarningDialog">
					<v-icon>mdi-alert-circle</v-icon>
					{{ $t('text.flushPEAKCache') }}
				</v-btn>
			</template>
			{{ $t('text.confirmFlushPEAKCacheText') }}
		</v-tooltip>

		<!-- Confirm PEAK Cache flush -->
		<Dialog ref="warningDialog"
			:confirmLabel="$t('text.confirm')"
			:cancelLabel="$t('text.cancel')"
			:confirm-handler="onCacheFlushConfirmed"
			:cancel-handler="onCacheFlushCancelled"
			:showClose="false"
			:isDelete="true"
			:title="$t('text.flushPEAKCache')"
			:height="'360px'"
			:width="'540px'"
		>
			<template #content>
				<v-row>
					<img class="warningDialogImage" src="@/assets/icons/icon-warning.svg" style="margin: 40px;" />
					<v-col class="text-col">
						<h1>{{$t('text.confirmFlushPEAKCache')}}</h1>
						<div class="dialogText">{{$t('text.confirmFlushPEAKCacheText')}}</div>
					</v-col>
				</v-row>
			</template>
		</Dialog>
	</div>
</template>

<script>
import Dialog from '../../../components/common/Dialog.vue';
import Common from '../../../mixins/Common.vue';
import eventBus from '@/utils/eventBus.js'

export default {
	components: { Dialog },
	mixins: [Common],

	data: () => ({
	}),
	
	methods: {
		showWarningDialog() {
			this.$refs.warningDialog.show = true
		},
		async onCacheFlushConfirmed() {
			await this.flushPeakCache()
			this.$refs.warningDialog.show = false
		},
		onCacheFlushCancelled() {
			this.$refs.warningDialog.show = false
		},
		async flushPeakCache() {
			try {
				this.loading = true
				await this.$httpGet(`/packageTravel/flushPEAKCache?clientId=${this.$store.state.selectedClient.sys.id}`)
				eventBus.$emit('addToastMessage', this.$t('text.changesSuccessfullyProcessed'), 'success')
			}
			catch(e) {
				console.log('Error flushing PEAK cache', e)
				eventBus.$emit('addToastMessage', this.$t('text.errorProcessingChanges'), 'error')
			}
			finally {
				this.loading = false
			}
		}
	}

}

</script>

<style scoped>
h1 { line-height: 1em; font-size: 18pt; }
.dialogText { font-size: 12pt; }
</style>